import Actions from "../../components/action.vue";
import HeaderCheckbox from "@/components/header-checkbox.js";
import GameEditor from "@/components/GameEditor.vue";
import LangEditor from "@/components/LangEditor.vue";
import GameRenderer from "@/components/GameRenderer.vue";
import { secToHour } from "@/components/utils";
import Checkbox from "@/components/checkbox.js";
import { GridOptions, ColDef, ValueSetterParams, ValueGetterParams, ValueFormatterParams } from "ag-grid-community";
import { IEndpoints } from "../Settings/model";
import { Ref } from "vue";

export function gridOptions(endpoints: Ref<IEndpoints>): GridOptions {
    return {
        pagination: true,
        paginationPageSize: 500,
        context: {
            actionCallback: null,
            actionIcons: [
                {
                    icon: "edit",
                    tooltip: "Edit",
                },
                {
                    icon: "save",
                    tooltip: "Save",
                },
            ],
        },
        rowSelection: "multiple",
        defaultColDef: {
            // allow every column to be aggregated
            enableValue: true,
            // allow every column to be grouped
            enableRowGroup: true,
            // allow every column to be pivoted
            enablePivot: true,
            resizable: true,
            sortable: true,
            filter: true,
        },
        sideBar: {
            toolPanels: [
                {
                    id: "columns",
                    labelDefault: "Columns",
                    labelKey: "columns",
                    iconKey: "columns",
                    toolPanel: "agColumnsToolPanel",
                },
                {
                    id: "filters",
                    labelDefault: "Filters",
                    labelKey: "filters",
                    iconKey: "filter",
                    toolPanel: "agFiltersToolPanel",
                },
            ],
        },

        onGridReady: () => {},
        components: {
            checkbox: Checkbox,
            gameRenderer: GameRenderer,
        },
        getRowId: function (params) {
            return params.data.hid;
        },
        rowData: [],
        columnDefs: columDefs(endpoints),
        enableCellChangeFlash: true,
        // refreshCells: true
    };
}

function columDefs(endpoints: Ref<IEndpoints>): ColDef[] {
    return [
        {
            colId: "checkbox",
            maxWidth: 50,
            checkboxSelection: true,
            suppressMenu: true,
            headerCheckboxSelection: true,
        },
        {
            headerName: "HID",
            field: "hid",
            width: 120,
            editable: true,
            hide: true,
        },
        {
            headerName: "TID",
            field: "tid",
            width: 120,
            editable: true,
            hide: false,
        },

        {
            headerName: "Partner name",
            field: "partnerName",
            width: 150,
            editable: true,
        },

        {
            headerName: "Table name",
            field: "tableName",
            width: 100,
            editable: true,
        },

        // {
        //     headerName: "Background image",
        //     field: "customization.lobby_background",
        //     width: 150,
        //     editable: false,
        // },

        {
            headerName: "Model",
            field: "tableModel",
            width: 100,
            editable: true,
        },
        {
            headerName: "Version number",
            field: "version.version",
            width: 100,
            editable: true,
        },
        {
            headerName: "Update enabled",
            field: "flags.updateEnabled",
            width: 100,
            editable: true,
            cellRenderer: "checkbox",
        },
        {
            headerName: "Log enabled",
            field: "flags.logEnabled",
            width: 100,
            editable: true,
            cellRenderer: "checkbox",
        },
        {
            headerName: "Touchguard",
            field: "flags.touchGuardEnabled",
            width: 100,
            editable: true,
            cellRenderer: "checkbox",
        },
        {
            headerName: "Pow.Email",
            field: "flags.powOnEmailEnabled",
            width: 100,
            editable: true,
            cellRenderer: "checkbox",
        },
        {
            headerName: "Lobby endpoint",
            field: "endpoint.lobby",
            width: 100,
            editable: true,
            // cellEditorSelector: (params) => {
            //     return {
            //         component: "agRichSelectCellEditor",
            //         params: {
            //             values: ["", ...endpoints.value.lobby],
            //         },
            //         popup: true,
            //     };
            // },
        },
        {
            headerName: "Table endpoint",
            field: "endpoint.table",
            width: 100,
            editable: true,
            // cellEditorSelector: (params) => {
            //     return {
            //         component: "agRichSelectCellEditor",
            //         params: {
            //             values: ["", ...endpoints.value.table],
            //         },
            //         popup: true,
            //     };
            // },
        },
        {
            headerName: "Game endpoint",
            field: "endpoint.game",
            width: 100,
            editable: true,
            // cellEditorSelector: (params) => {
            //     return {
            //         component: "agRichSelectCellEditor",
            //         params: {
            //             values: ["", ...endpoints.value.game],
            //         },
            //         popup: true,
            //     };
            // },
        },
        {
            headerName: "Free",
            field: "free",
            width: 80,
            editable: true,
            cellRenderer: "checkbox",
        },
        {
            headerName: "Comment",
            field: "comment",
            width: 200,
            editable: true,
        },
        {
            headerName: "Actions",
            field: "action",
            // width: 160,
            cellRenderer: Actions,
            suppressMenu: true,
            pinned: "right",
            // suppressSizeToFit: true,
        },
    ];
}
